<template>
  <span v-if="order">{{ orderStatus }}</span>
</template>
<script>
export default {
  props: { order: { type: Object, required: true } },
  computed: {
    orderStatus() {
      return global.vm.$t(
        `order.orderStatusDescr[${this.order.orderStatusId}]`
      );
    }
  }
};
</script>
