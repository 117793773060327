<template>
  <div class="generic-list scroll-container">
    <CategoryTitle :category="category" />

    <OrdersTable
      v-if="orders && orders.length > 0"
      :orders="orders"
      @delete="deleteOrder"
      @addAllToCart="addAllToCart"
      @editOrder="editOrder"
    ></OrdersTable>
    <v-pagination
      circle
      v-if="orderPager && orderPager.totPages > 1"
      :value="pageFilter"
      :page="orderPager.selPage"
      :length="orderPager.totPages ? orderPager.totPages : 0"
      :totalVisible="6"
      @next.stop.prevent="handlePageFilter"
      @prev="handlePageFilter"
      @input="handlePageFilter"
      class="py-6"
    ></v-pagination>

    <v-card
      light
      outlined
      v-if="orders && orders.length == 0"
      class="pa-2 text-center"
      style="width:100%;"
    >
      <v-card-title class="headline">Nessun ordine trovato</v-card-title>
    </v-card>
    <!-- </v-container> -->
    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>

<style scoped lang="scss"></style>

<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import OrdersTable from "@/components/orders/OrdersTable.vue";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Orders",
  data() {
    return { orders: {}, orderPager: {} };
  },
  mixins: [reload, categoryMixin],
  components: {
    CategoryTitle,
    OrdersTable
    // OrderStatusBadge
    // OrderListElement
  },

  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    })
  },
  methods: {
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart"
    }),
    reload() {
      OrderService.getOrders(
        this.pageFilter,
        process.env.VUE_APP_ORDERS_LIST_PAGE_SIZE,
        true
      ).then(data => {
        this.orders = data.orders;
        this.orderPager = data.page;
      });
    },
    async deleteOrder(orderId) {
      await OrderService.deleteOrder(orderId);
      this.reload();
      let order = await OrderService.getOrder(orderId);
      AnalyticsService.refund(order);
    },
    async editOrder(orderId) {
      try {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.confirmOrderEdit")
        });
        if (res) {
          OrderService.editOrder(orderId).then(() => {
            this.loadCart();

            this.$router.push({
              name: "Checkout"
            });
          });
        }
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.errorOnOrderEditConfirm")
        });
        return null;
      }
    },
    async addAllToCart(orderId) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllFromOrder")
      });
      if (res) {
        await this.addProductsFromOrder(orderId);
      }
    },
    handlePageFilter(page) {
      this.$store.dispatch("category/setFilterPage", page);
      this.reload();
      this.$vuetify.goTo(0);
    }
  },
  created() {
    this.reload();
  }
};
</script>
