<template>
  <div class="generic-list">
    <v-row no-gutters class="row-header hidden-sm-and-down text-body-2">
      <v-col cols="2" class="font-weight-bold">
        <span>{{ $t("orders.header.orderNumber") }}</span>
      </v-col>

      <v-col cols="3" md="1" class="text-center">
        <strong>{{ $t("orders.header.status") }}</strong>
      </v-col>

      <v-col cols="5" md="4" offset="1">
        <strong>{{ $t("orders.header.delivery") }}</strong>
      </v-col>

      <v-col cols="1" class="text-center">
        <strong>{{ $t("orders.header.total") }}</strong>
      </v-col>

      <v-col cols="1" md="3" class="hidden-sm-and-down"> </v-col>
    </v-row>

    <v-list>
      <v-list-item
        v-for="order in orders"
        :key="order.orderId"
        v-bind:to="'/profile/orders/' + order.orderId"
        :class="{ active: order.orderStatusId === 3 }"
      >
        <v-row no-gutters align="center" class="text-caption text-sm-body-2">
          <v-col cols="2" class="hidden-sm-and-down">
            <div class="font-weight-bold primary--text">
              #{{ order.orderId }}
            </div>
          </v-col>

          <v-col
            cols="2"
            sm="1"
            class="text-center default--text font-weight-bold"
          >
            <OrderStatusBadge v-bind:order="order" />
          </v-col>

          <v-col
            cols="7"
            md="4"
            offset="1"
            offset-sm="1"
            class="d-flex flex-row align-center"
          >
            <img
              aria-hidden
              :src="serviceImg"
              height="30"
              width="30"
              class="mr-2"
            />
            <span class="text-capitalize d-flex flex-column">
              <span class="d-block d-md-none">
                Ordine n.{{ order.orderId }}
              </span>
              <span
                v-html="
                  $t(
                    'navbar.address.' +
                      order.shippingAddress.addressTypeId +
                      '.ordersList',
                    order.shippingAddress
                  )
                "
              >
              </span>
              <span class="timeslot">
                {{
                  $dayjs(
                    order.timeslot.date + " " + order.timeslot.beginTime,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).format("D MMMM - HH:mm")
                }}
              </span>
            </span>
          </v-col>

          <v-col cols="2" sm="3" md="1" class="text-right text-md-center">
            <strong> {{ $n(order.grossTotal, "currency") }}</strong>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="d-none d-md-flex flex-row justify-center justify-sm-end mt-4 mt-sm-0"
          >
            <div
              class="d-flex flex-column justify-center align-center  mr-1"
              v-if="order.isEditable"
            >
              <v-btn
                @click.stop.prevent="editOrder(order.orderId)"
                small
                class="primary white--text"
                icon
                fab
              >
                <v-icon>$edit</v-icon>
              </v-btn>
              <span class="primary--text text-center small">{{
                $t("orders.button.edit")
              }}</span>
            </div>
            <div
              class="d-flex flex-column justify-center align-center  mr-1"
              v-if="order.isDeletable"
            >
              <v-btn
                @click.stop.prevent="deleteOrder(order.orderId)"
                small
                class="promo--text"
                icon
                fab
              >
                <v-icon>$delete</v-icon>
              </v-btn>
              <span class="promo--text text-center small">{{
                $t("orders.button.delete")
              }}</span>
            </div>
            <div
              class="d-flex flex-column justify-center align-center  ml-4"
              v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
            >
              <v-btn
                @click.stop.prevent="addAllToCart(order.orderId)"
                small
                class="primary white--text"
                icon
                fab
                v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
              >
                <v-icon>$cart</v-icon>
              </v-btn>
              <span class="primary--text text-center small">{{
                $t("orders.button.reorder")
              }}</span>
            </div>
            <div
              class="d-flex flex-column justify-center align-center ml-4"
              v-if="order.isPayable"
            >
              <span class="primary--text text-center small">{{
                $t("orders.label.isPayable")
              }}</span>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="d-flex d-md-none flex-row justify-center justify-sm-end mt-2"
            v-if="order.isPayable"
          >
            <div
              class="d-flex flex-column justify-center align-center"
              v-if="order.isPayable"
            >
              <span class="primary--text text-center small">{{
                $t("orders.label.isPayable")
              }}</span>
            </div>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style scoped lang="scss">
.active {
  background-color: var(--v-primary-lighten2) !important;
}
.small {
  font-size: 10px;
  min-height: 16px;
  text-transform: uppercase;
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge";
import cartInfo from "~/mixins/cartInfo";
import { mapState, mapGetters } from "vuex";
export default {
  name: "OrdersTable",
  props: {
    orders: {
      type: Array
    },
    delivered: {
      type: Boolean
    }
  },
  mixins: [cartInfo],
  components: {
    OrderStatusBadge
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslot() {
      return this.getItemByProductId(this.product.productId);
    },
    quantity() {
      return this.item ? this.item.quantity : 0;
    },
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    }
  },
  methods: {
    rateOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },
    async deleteOrder(orderId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });
      if (res) {
        this.$emit("delete", orderId);
      }
    },
    editOrder(orderId) {
      this.$emit("editOrder", orderId);
    },
    addAllToCart(orderId) {
      this.$emit("addAllToCart", orderId);
    }
  },
  mounted() {}
};
</script>
